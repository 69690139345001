@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    background: goldenrod;
}

body::-webkit-scrollbar-thumb {
    background-color: black;
}

main {
    min-height:calc(100vh - 210px);
    background: rgb(255,81,47);
    background: linear-gradient(90deg, rgba(255,81,47,1) 0%, rgba(221,36,118,1) 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.main-title {
    color:white;
    text-shadow: 3px 2px black;
    font-size:4rem;
    font-weight:800;
    text-align:center;
    width:100%;
    padding-top:180px;
}

.header {
    position:fixed;
    z-index:2;
    width:100%;
    background: rgb(72,76,82);
    background: linear-gradient(90deg, rgba(72,76,82,1) 0%, rgba(24,26,30,1) 100%);
    padding: 10px;
    height:80px;
    display:flex;
    justify-content: center;
    align-items:center;
}
.header-links {
    display:flex;
    justify-content:flex-end;
    font-size:1.3rem;
    font-weight:100;
    align-items:end;
}
.header-links a {
    color:white;
    margin:0 30px;
}
.header-links a:hover {
    color:goldenrod;
}
.header-logo {
    max-heigh:70px;
    max-width:250px;
}
.footer {
    background: rgb(72,76,82);
    background: linear-gradient(90deg, rgba(72,76,82,1) 0%, rgba(24,26,30,1) 100%);
    color:white;
    height:210px;
    display:flex;
    justify-content:center;
}
.footer-title {
    font-size:1.7rem;
    font-weight:bold;
}

.footer-desc {
    font-size:1rem;
}
.footer-links-section {
    width:50%;
    display:flex !important;
    flex-direction:column !important;
}
.footer-links {
    padding-top:30px;
    display:flex;
    justify-content:space-between;
}
.footer-links a {
    color: white !important;
    font-size:1rem;
    line-height:1.6rem;

}
.footer-links a:hover {
    color:goldenrod;
    text-decoration:underline;
}
.footer-info {
    padding-top:30px;
}
.footer-info a {
    color:white;
    font-size:1rem;
}
.footer-info .icon {
    font-size:2rem;
    margin:0 10px 0 0;
}
.footer-info-line {
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:15px;
}
.footer-row {
    padding-bottom:30px;
    margin-bottom:10px;
    border-bottom: 1px solid #444444;
}
.copyright {
    width:100%;
    text-align:center;
}
.footer-social-img {
    margin:10px 10px 0 0;
    width:35px;
}
.footer-social-img:hover {
    filter:brightness(50%);
}
.title {
    padding-top:100px;
}

/* Homepage */
.stay-in-touch {
    padding:30px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}
.newsletter-title {
    font-weight:bold;
    font-size:3rem;
    margin-bottom:30px;
}
.title-homepage {
    padding-top:290px;
}
.newsletter-input {
    width:300px;
}

/* Contact Us Page */
.form-container {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
}

input,
textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 16px;
    resize: vertical;
}
textarea {
    height:100px;
}

input[type="submit"] {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #45a049;
}
.main-contact-us, .main-privacy-policy {
    display:flex;
    justify-content:center;
    padding-top:100px;
}
.contact-form-box {
    border-radius:5px;
    width:30vw;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding:50px;
    border-radius:10px;
    color:white;
    background: rgb(72,76,82);
    background: linear-gradient(90deg, rgba(72,76,82,1) 0%, rgba(24,26,30,1) 100%);
    position:relative;
    margin-bottom:20px;
}
.contact-form-title {
    font-size:2rem;
    font-weight:bold;
    color:white;
    width:100%;
    text-align:center;
}
.loader {
    display:flex;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.6);
    border-radius:10px;
    position: absolute;
    width:100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

/* Privacy Policy Page */
.privacy-policy-box {
    border-radius:5px;
    width:60vw;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding:50px;
    border-radius:10px;
    color:black;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(188,190,193,1) 100%);
    position:relative;
    margin-bottom:100px;
}

/* Careers Page */
.careers-info {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(188,190,193,1) 100%);
}
.main-careers, .careers-right {
    min-height:calc(100vh - 210px);
}
.careers-right {
    padding:100px 50px 50px 50px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(188,190,193,1) 100%);
}
.careers-desc {
    font-size:1rem;
}

/* Repertoire Page */
.main-repertoire {
    min-height:450px;
}
.repertoire-desc {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(188,190,193,1) 100%);
    padding:50px;
    min-height:450px;
    display:flex;
    justify-content:center;
}
.repertoire-inner {
    font-size:1rem;
}

/* Who We Are Page */
.about-orchestra-section {
    background-color:black;
    padding:30px;
}
.about-orchestra-section h2, .about-cast-right h2,
.about-vision-left h2, .about-ds-desc h2 {
    font-weight:100;
    font-size:2.5rem;
}
.about-orchestra-right {
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:100;
}
.about-orchestra-left {
    color: #dfa141;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}
.about-logo {
    max-width:50%;
}
.about-desc {
    max-width:600px;
}
.about-cast-section {
    padding:0px;
    color:black;
}
.about-cast-left {
    border-bottom-right-radius: 80px;
}
.about-cast-right {
    padding:0 30px 30px 50px;
}
.orchestra-cast-col {
    padding-left:20px;
    font-size:1rem;
    line-height:1.7rem;
}
.orchestra-cast-col-left {
    font-size:1rem;
    line-height:1.7rem;
}
.about-vision-section {
    margin-top: 70px;
}
.about-vision-left {

}
.about-vision-desc {
    padding:30px 70px 30px 50px;
    line-height:1.7rem;
}
.about-vision-right {
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}
.about-ds-section {
    margin-top:70px;
}
.magic-section {
    margin-bottom:50px;
}
.about-ds-left {
    border-top-right-radius: 80px;
}
.about-ds-desc {
    padding:50px;
}
.about-ds-desc p {
    line-height: 1.7rem;
}
.home-sections {
    display:flex;
    justify-content:center;
    padding:50px;
    min-height:200px;
}
.magic-section-left {
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
}
.home-sections-col {
    margin:20px;
}
.home-technicals-col-img {
    display:flex;
    justify-content:center;
}
.home-technicals-col-img img {
    max-width:70%;
    border-radius:50%;
}
.home-technicals-col-text {
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.technicals-title {
    font-weight:100;
    font-size:2.5rem;
    line-height:4rem;
}
.technicals-desc {
    padding:20px;
    font-size:1rem;
    line-height:1.7rem;
}